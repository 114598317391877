<template>
  <PlanBackground class=" ">
    <div class="px-3 row align-items-center justify-content-center" v-if="!isLoading">
      <div class="col-md-2">
        <div class="plan-basic-info">
          <div class="plan-title">
            {{ plan.name }}
          </div>
          <div class="plan-price">${{ Math.floor(plan.price) }}</div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row justify-content-between my-3">
          <div
            class="col-12 col-sm-6 py-2"
            v-for="(analysis, i) in planAnalysis"
            :key="i"
          >
            <div class="" style="white-space: nowrap">
              <span class="analysis-text mr-1">{{ analysis.text }}</span>
              <span
                style="color: #d2d8e0; font-weight: 400; font-size: 14px"
                v-if="analysis.planValue > 0"
                >{{ analysis.userValue }}/{{ analysis.planValue }}</span
              >
              <span
                style="color: #d2d8e0; font-weight: 400; font-size: 14px"
                v-else
                >{{ analysis.userValue }}/&infin;</span
              >
            </div>
            <div class="progress-bar w-100">
              <div
                class="active-bar"
                :style="{ width: getProgressBarPercent(analysis) }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2 px-md-0">
        <div class="plan-basic-info my-md-4">
          <button class="btn btn-custom float-md-right" @click="openUpgradePlan">Upgrade</button>
        </div>
      </div>
    </div>

     <div class="px-3 row align-items-center justify-content-center" v-else>
      <div class="col-md-2">
        <div class="plan-basic-info">
         <b-skeleton-img></b-skeleton-img>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row justify-content-between my-3">
          <div
            class="col-12 col-sm-6 py-2"
            v-for="(i) in 4"
            :key="i"
          >
            <div class="" style="white-space: nowrap">
              <b-skeleton type="input"></b-skeleton>
            </div>
           
          </div>
        </div>
      </div>
      <div class="col-md-2 px-md-0">
        <div class="plan-basic-info my-md-4">
         <b-skeleton type="button"></b-skeleton>
        </div>
      </div>
    </div>
  </PlanBackground>
</template>
<script>
import { mapGetters} from "vuex";
import PlanBackground from "./PlanBackground.vue";

export default {
  components: {
    PlanBackground,
  },
  props: ["plan", "planAnalysis","isLoading"],

  methods: {
    getProgressBarPercent(analysis) {
      return analysis.planValue > 0
        ? (analysis.userValue * 100) / analysis.planValue + "%"
        : 100 + "%";
    },
      openUpgradePlan(){
       
        let webUsers = ['web','bc','wordpress']
           if( webUsers.includes(this.getUserType)){
            let url = window.location.origin + `/manage/account/pricing`
            window.open(url,"_self")
      }
      if(this.getUserType == 'shopify') {
       const envPath=process.env.VUE_APP_SHOPIFY_UPGRADE_PLAN_PATH;
      const  url= `https://${this.getNavbarData.name}${envPath}manage/subscription/plans`
      window.open(url,"_parent")
      } 
      if(this.getUserType == 'wix'){
         let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`
      window.open(url,"_blank")
      }
       
    }
  },
  data() {
    return {};
  },
  computed:{
     ...mapGetters([ "getNavbarData"]),
    getUserType(){
      return this.getNavbarData.userType
    },
    UserAccountData() {
      return this.getNavbarData;
    },
  }
};
</script>
<style scoped>
.plan-title {
  font-size: 20px;
  font-weight: 600;
}
.plan-price {
  font-size: 33px;
  font-weight: 600;
}
.btn-custom {
  background: #ffa201;
  border-radius: 8px;
  padding: 10px 16px;
  color: #ffff;
  border: none;
}

.btn-custom:active {
  background: #ba8221;
}
.progress-bar {
  height: 5px;
  position: relative;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
}
.active-bar {
  background: #ffa201;
  border-radius: 50px;
  height: 5px;
}
.analysis-text {
  font-size: 18px;
  font-weight: 600;
}
@media only screen and (max-width: 910) {
  .analysis-text {
    font-size: 14px;
  }
}
</style>
